<template>
  <v-dialog
    :max-width="$dialog.medium"
    no-click-animation
    v-model="dialog"
    persistent
    scrollable
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card v-if="show">
      <v-card-title class="font-weight-bold secondary--text">
        {{ response.id ? `Update canned response "${response.name}"` : 'Create Canned Response' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
            :rules="[rules.required]"
            v-model="response.name"
            label="Name"
            outlined
          ></v-text-field>
          
          <MessageField v-model="response.message" forceShow />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :loading="status.creating"
          @click="validateForm()"
          color="primary" 
          small
        >Submit</v-btn>

        <v-btn 
          :disabled="status.creating"
          @click="closeDialog()" 
          depressed 
          small
        >Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

import MessageField from '@/components/MessageField'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      show: true,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    MessageField
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.canned_responses.status,
      response: state => state.canned_responses.response,
    })
  },

  watch: {
    'dialog': function () {
      this.$forceUpdate()
    },

    'response': function () {
      this.show = false
      
      setTimeout(() => {
        this.show = true
      }, 1000)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('canned_responses', [
      'createResponse'
    ]),

    showDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.$store.commit('canned_responses/setResponseData', {})
      this.$refs.form.reset()
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        let type = this.$route.name == 'RequestCannedResponses' ? 'request' : 'order'
        
        Promise.resolve(this.createResponse(type))
        .then(() => {
          this.closeDialog()
        })
      }
    }
  }
}
</script>